import React from 'react';

import * as Core from 'components/core';
import Layout from 'components/layout/Base';

const FeatureContent: React.FC = () => {
  return (
    <Layout title="Through the Years">
      <Core.ContentWrapper>
        <Core.Flag>75th Anniversary Timeline</Core.Flag>
        <Core.PrimaryHeading>Through the Years</Core.PrimaryHeading>
        <iframe
          src="https://cdn.knightlab.com/libs/timeline3/latest/embed/index.html?source=1dTa2pqZnnFyalp_AuqXLHz_F-l8Ct_JKrJPqnpYBFcY&font=Default&lang=en&initial_zoom=2&height=650"
          width="100%"
          height={650}
          allowFullScreen
          frameBorder={0}
        ></iframe>
        <Core.Share />
      </Core.ContentWrapper>
    </Layout>
  );
};

export default FeatureContent;
